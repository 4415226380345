import {
  Box, Container, useClipboard
} from '@chakra-ui/react';
import React from 'react';
import Terminal, { ColorMode, TerminalOutput } from 'react-terminal-ui';
import './App.css';

function LoadingBar(props) {
  const [counter, setCounter] = React.useState(0);
  const [bar, setBar] = React.useState("");

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      const newTarget = Math.min(50, counter + 1);
      setCounter(newTarget);
      setBar("▋".repeat(newTarget))
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [counter]);

  return <span >
    {counter * 2}%  {bar}
  </span>
}

function ClickCopyOutput() {
  const { hasCopied, onCopy } = useClipboard("datum.sh clone dataset.sh/luke/awesome-dataset")
  const copyInstruction = !hasCopied ? "click the above command to copy" : "command copied";
  return <>
    <TerminalOutput>  <span onClick={onCopy}>datum.sh clone dataset.sh/luke/awesome-dataset</span></TerminalOutput>
    <TerminalOutput >  <span onClick={onCopy}>({copyInstruction})</span> </TerminalOutput>
  </>
}

const TerminalController = (props = {}) => {
  const [terminalLineData, setTerminalLineData] = React.useState([
    <TerminalOutput>Share and download you datasets with</TerminalOutput>,
    <TerminalOutput>datum.sh and dataset.sh (coming soon)</TerminalOutput>,
    <TerminalOutput>try:</TerminalOutput>,
    <ClickCopyOutput />,
  ]);

  const handleInput = (terminalInput) => {
    if (terminalInput.startsWith('datum.sh')) {
      const parts = terminalInput.split(/\s+/);
      console.log(parts)
      if (parts[1] === 'clone') {
        const dsPath = parts[2]
        setTerminalLineData([
          <TerminalOutput>Downloading 5 commits from</TerminalOutput>,
          <TerminalOutput>  {dsPath}</TerminalOutput>,
          <LoadingBar key="loader" />,
        ])
      } else {
        setTerminalLineData([
          <TerminalOutput>datum.sh usage:</TerminalOutput>,
          <TerminalOutput>  clone  -  clone a dataset </TerminalOutput>,
          <TerminalOutput>  help   -  show this message</TerminalOutput>,
          <TerminalOutput></TerminalOutput>,
          <TerminalOutput>  More detail coming soon...</TerminalOutput>,
        ])
      }

    } else if (terminalInput === 'clear') {
      setTerminalLineData([
        <TerminalOutput>Share and download you datasets with</TerminalOutput>,
        <TerminalOutput>datum.sh and dataset.sh (coming soon)</TerminalOutput>,
        <TerminalOutput>try:</TerminalOutput>,
        <ClickCopyOutput />,
      ])
    } else if (terminalInput === 'help') {
      setTerminalLineData([
        <TerminalOutput>Usage:</TerminalOutput>,
        <TerminalOutput>  datum.sh  -  manage your datasets </TerminalOutput>,
        <TerminalOutput>  help      -  show this message</TerminalOutput>,
        <TerminalOutput>  clear     -  clear screen</TerminalOutput>
      ])
    } else if (terminalInput === '') {
      setTerminalLineData([
        <TerminalOutput>Share and download you datasets with</TerminalOutput>,
        <TerminalOutput>datum.sh and dataset.sh (coming soon)</TerminalOutput>,
        <TerminalOutput>try:</TerminalOutput>,
        <ClickCopyOutput />,
      ])
    } else {
      setTerminalLineData([
        <TerminalOutput>{terminalInput}: command not recognized</TerminalOutput>,
        <TerminalOutput>Share and download you datasets with</TerminalOutput>,
        <TerminalOutput>datum.sh and dataset.sh (coming soon)</TerminalOutput>,
        <TerminalOutput>try:</TerminalOutput>,
        <ClickCopyOutput />,

      ])
    }
  }

  return (
    <div className="container">
      <Terminal
        name='Loading dataset.sh...'
        colorMode={ColorMode.Dark}
        onInput={handleInput}>
        {terminalLineData}
      </Terminal>
    </div>
  )
};


function App() {
  return (
    <Box
      bgGradient={{ sm: 'linear-gradient(to right top, #5f2b49, #5c3154, #57375f, #4f3d68, #464470, #3a4d79, #2b5580, #135d85, #00698a, #00748a, #007f87, #138981)' }}
      py={{ base: '12', md: '24' }}
      minH="100vh"
    >
      <Container
        maxW="3xl"
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
      >
        <TerminalController />
      </Container>

    </Box>
  );
}

export default App;
